:root {
    font-size: 60%;

    --color-background: #272727 ;
   
    --color-primary-lighter: #F28A5C;
    --color-primary-light: #F3A27C;
    --color-primary: #ed682f;
    --color-primary-dark: #F28252;
    --color-primary-darker: #E05B22;

    --color-title-in-primary: #FFFFFF;
    --color-text-base: #6A6180;
    --color-line-in-white: #E6E6F0;
    --color-input-background: #F0F0F7;
    --color-box-base: #FFFFFF;
    --color-danger: #dc3545;
}

* {
    margin : 0;
    padding: 0;
    box-sizing: border-box;
}

html, body, #root {
    height: 100%;
}

body {
    background: var(--color-background);
}

#root {
    display: flex;
    align-items: center;
    justify-content: center;
}

body, input, button, textarea {
    font: 500 1.6rem Poppins;
    color: var(--color-text-base);
}

.container {
    width: 80vw;
    max-width: 700px;
}

@media(min-width: 1000px) {
    :root {
        font-size: 62.5%;
    }
}